import React from "react"
import { Helmet } from "react-helmet"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MainImage from "../images/marketing/restaurant-business-plan-main-image-LaunchPlan-software.jpg"
import DescriptionImage from "../images/marketing/coffee-shop-business-plan-company description-LaunchPlan-software.jpg"
import ExecutiveSummaryImage from "../images/marketing/food-truck-business-plan-executive-summary-LaunchPlan-software.jpeg"
import FinanacialPlanImage from "../images/marketing/brewery-business-plan-financials-LaunchPlan-software.jpg"
import OperationsImage from "../images/marketing/bakery-business-plan-operations-LaunchPlan-software.jpg"
import MarketingImage from "../images/marketing/catering-business-plan-marketing-LaunchPlan-software.jpg"
import PublicLayout from "../components/layouts/PublicLayout"
import Button from "../components/button"

const styles = theme => ({
  root: {
    backgroundColor: "white",

    '& img': {
      marginBottom: '40px',
      maxWidth: '100%'
    }
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
  },
  text: {
    margin: '0 auto',
    maxWidth: 600,

    '& p': {
      marginBottom: '20px'
    },

    '& h2, h3': {
      margin: '100px 0 30px'
    }
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  headline: {
    margin: "1rem 0",
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  footer: {
    marginTop: '120px'
  }
})

const RealEstatePage = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"Write Business Plans for Restaurants and Other Food Services with LaunchPlan"}
      canonicalUrl="https://launchplan.com/restaurant-business-plan-software"
    >
      <Helmet>
        <title>Restaurant Business Plan Software | LaunchPlan</title>
        <meta
          name="description"
          content="Write food-service business plans for restaurants, food trucks, bars, bakeries, breweries, catering, coffee shops and more using LaunchPlan software."
        />
        <meta
          property="og:title"
          content="Restaurant Business Plan Software | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/restaurant-business-plan-software" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        <img src={MainImage} alt='' />
        <div className={classes.text}>
          <Typography>
            The success of a restaurant or another food-service business is dependent on so many distinct factors — it makes good planning essential. Your food concept and cuisine might be impeccable, but an inadequate service style or overly saturated market could still sink your business.
          </Typography>
          <Typography>
            Writing a business plan — whether it’s for a restaurant, bar, food truck, coffee shop, brewery, bakery or catering business — requires you to think through these many moving parts to chart a course to profitability.
          </Typography>
          <Typography>
            Even if you don’t need a business plan for funding, simply going through the process of creating one increases your chance of success. A recent Harvard Business Review study found that entrepreneurs who write business plans are <a target="_blank" href="https://hbr.org/2017/07/research-writing-a-business-plan-makes-your-startup-more-likely-to-succeed">16% more likely to achieve viability</a> than those who do not.
          </Typography>
          <Typography>
            The outline of a business plan features five main areas, including an executive summary, a company description, an operations plan, marketing & sales plans, and a financial plan.
          </Typography>
          <Typography>
            Read on for an overview, or let our restaurant <a target="_blank" href="https://launchplan.com/">business plan software</a> walk you through each step and automate your financial forecasts with a 60 day risk-free guarantee. You'll get a 100% refund if you're not 100% satisfied..
          </Typography>
          <Button
            className={classes.freeTrailButton}
            href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}
            onClick={() =>
              trackCustomEvent({
                category: "Restaurant Plan Help",
                action: "Click",
                label: "Free Trial In Overview",
              })
            }
          >
            {"Create Your Plan with LaunchPlan"}
          </Button>
          <Typography component="h2" variant="h4">
            Executive Summary
          </Typography>
          <div className={classes.separator}></div>
          <img src={ExecutiveSummaryImage} alt='' />
          <Typography>
            The executive summary addresses the key parts of your plan from a high level and is an expanded elevator pitch to potential investors and banks. It should answer common questions about your restaurant or food-service business, like its <a target="_blank" href="https://www.thebalancesmb.com/different-types-of-restaurant-concepts-2888698">food concept</a>, service style, physical layout, target market, neighborhood and surrounding businesses.
          </Typography>
          <Typography>
            The executive summary should be written as if it’s the only thing an investor will read — because they may not have time for anything else.
          </Typography>
          <Typography>
            As a food-service business, some tangible details to feature in the executive summary include:
          </Typography>
          <ul>
            <li>
              <Typography>
                A few illuminating statistics about the food-service market you’re entering

                (Ex. “With designated areas for operation, the food truck market in Columbus, Ohio, grew from 6 businesses in 2017 to 14 businesses in 2019, but there is not currently a truck serving Indian cuisine like ours.”)
              </Typography>
            </li>
            <li>
              <Typography>
                Four to six SMART (specific, measurable, assignable, realistic and time-based) goals for the first year of operations

                (Ex. “Win the Best of Jackson award for Best New Restaurant in 2021,” or “Average 230 beverages sold per day for the first six months of our coffee shop’s operation.”)
              </Typography>
            </li>
            <li>
              <Typography>
                A high-level overview of upfront expenses, the amount of funding needed and revenue projections over the first three years of the business
              </Typography>
            </li>
          </ul>
          <Typography component="h2" variant="h4">
            Company Description
          </Typography>
          <div className={classes.separator}></div>
          <img src={DescriptionImage} alt='' />
          <Typography>
            The company description goes into more detail about your business’ background and vision, including its history and team, as well as its industry and competitive landscape. It addresses the company’s long-term goals and how it plans to achieve them.
          </Typography>
          <Typography>
            This section also highlights how your company fits in with your market’s trends, and how it can stand apart from or coexist with competitors. As a food-service business, this is also a good place for you to include a sample menu and pricing.
          </Typography>
          <Typography>
            The anchors of the company description are the mission and vision statements. While the mission statement articulates the day-to-day benefits your company will provide to its customers, employees (including yourself) and shareholders; the vision statement takes a broader view of how you will move the market forward and benefit the community at large.
          </Typography>
          <Typography>
            For example, a vision statement might be to “provide a healthier, more sustainable food option for the Newark community.”
          </Typography>
          <Typography>
            Consider exploring the following aspects of your food-service market and how your company can thrive within it:
          </Typography>
          <ul>
            <li>
              <Typography>
                The availability and cost of locally sourced and sustainable food in your market, and how your company could benefit from this trend.
              </Typography>
            </li>
            <li>
              <Typography>
                The dining demographics of your market and the niche you plan to carve out. Is it younger, environmentally conscious millennials? Affluent baby boomers? Locals? Tourists?
              </Typography>
            </li>
            <li>
              <Typography>
                Is the restaurant’s neighborhood conducive to indoor or outdoor dining, or is it better suited for grab-n-go, takeout or delivery? Perhaps a mix of those services?
              </Typography>
            </li>
            <li>
              <Typography>
                If you are relatively new to the food-service industry, identify advisors with industry experience who can guide your food concept and service style in the market.
              </Typography>
            </li>
          </ul>
          <Typography component="h2" variant="h4">
            Operations Plan
          </Typography>
          <div className={classes.separator}></div>
          <img src={OperationsImage} alt='' />
          <Typography>
            If an investor makes it to your operations plan, then they’re likely intrigued and want the nitty-gritty on how you’ll turn your ideas into action. The operations plan details the physical assets, personnel and technology you will need to become a viable food-service business.
          </Typography>
          <Typography>
            This section of a business plan details capital and expense requirements like the physical location for your food-service business, its cooking and cleaning equipment, point-of-sale system, dinnerware, dining room furniture, delivery fleet, website, digital advertising, and signage.
          </Typography>
          <Typography>
            If you have a partner, an executive chef or a restaurant manager already defined, their roles in day-to-day operations should also be clearly described here, in addition to your own role. Detail the number and scope of kitchen staff who will serve under your chef.
          </Typography>
          <Typography>
            The roles and payroll for your front-of-house staff should also be clearly explained. Will they act as traditional waitstaff benefiting from tips, or work more behind the counter with some bussing? If you intend to instead automate some of these processes, outline the online or self-ordering systems you will use.
          </Typography>
          <Typography>
            With razor-thin margins in the food-service industry, you should clearly describe how you will source your food inventory and which higher-margin products will ensure profitability (ex. sales from alcohol or dessert items), even if they aren’t the most unique aspect of your food concept and menu.
          </Typography>
          <Typography component="h2" variant="h4">
            Marketing & Sales Plans
          </Typography>
          <div className={classes.separator}></div>
          <img src={MarketingImage} alt='' />
          <Typography>
            The marketing & sales plans detail who your target prospects will be, how you will reach them, and how you will convert them into paying customers.
          </Typography>
          <Typography>
            For example, if you plan to target millennials, you might invest in garnering positive reviews and running ads on Yelp, or promoting daily specials on Instagram. If you’re instead targeting affluent baby boomers (just <a target="_blank" href="https://www.pewresearch.org/internet/fact-sheet/social-media/">8% of Americans ages 65+ say they use Instagram</a>), you might focus on PR campaigns to be featured in local newspapers, magazines and online news sites.
          </Typography>
          <Typography>
            Once you’ve defined your niches, you can perform market research and develop a plan to reach them. For digital marketing, SEO tools like <a target="_blank" href="https://www.semrush.com/">SEMrush</a> and <a target="_blank" href="https://moz.com/">Moz</a> can reveal the volume of different food service Google searches (ex. “microbreweries in Raleigh NC”) so that you can gauge market demand. You then can check out the websites and social media presence for competitors who are appearing for these searches to get ideas or determine what you could do differently.
          </Typography>
          <Typography>
            If you plan to use third-party online ordering and delivery platforms like <a target="_blank" href="https://get.chownow.com/">ChowNow</a> or <a target="_blank" href="https://www.ubereats.com/">Uber Eats</a> to facilitate sales, this strategy should also be highlighted, accounting for those platforms’ fees as well.
          </Typography>
          <Typography component="h2" variant="h4">
            Financial Plan
          </Typography>
          <div className={classes.separator}></div>
          <img src={FinanacialPlanImage} alt='' />
          <Typography>
            Often the most intimidating part of a business plan, the financial plan, includes your profit & loss statement, your balance sheet and your cash flow. Our business plan software for restaurants simplifies this process by asking for your financial numbers like TurboTax does for taxes, and then automating them into clean charts and graphs.
          </Typography>
          <Typography component="h3" variant="h5">
            Profit & Loss Statement
          </Typography>
          <Typography>
            The profit & loss statement breaks down your estimated profit and loss over the first few years of business, taking into account revenues from meal sales, your inventory costs, operation expenses, employee payroll and corporate taxes.
          </Typography>
          <Typography>
            The expectations for the time it takes to become profitable will vary based on the type of food-service company you’re starting. A brewery, for example, will require a larger upfront investment to acquire the brewing equipment and space to store and refine the product, compared to a food truck, which has relatively modest capital expenditures.
          </Typography>
          <Typography>
            In any case, you’ll want your profit & loss to show an upward trajectory over the first few years.
          </Typography>
          <Typography component="h3" variant="h5">
            Balance Sheet
          </Typography>
          <Typography>
            While your profit & loss statement focuses on trends, your balance sheet details your financial status at a set point in time, laying out your assets, liabilities and equity.
          </Typography>
          <Typography component="h3" variant="h5">
            Cash Flow
          </Typography>
          <Typography>
            Lastly, your cash flow shows the net amount of cash or cash-equivalents flowing in and out of your restaurant or food-service business, broken down into operations, investing and financing.
          </Typography>
          <div className={classes.footer}>
            <div className={classes.separator}></div>
            <Typography>
              Need more help writing your restaurant business plan? Let LaunchPlan walk you through each step.
            </Typography>
            <Typography>
              LaunchPlan lets you collaborate with unlimited team members and then easily download your professional business plan. Impress potential investors and put your company on track for success.
            </Typography>
            <Button
              className={classes.freeTrailButton}
              href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}
              onClick={() =>
                trackCustomEvent({
                  category: "Restaurant Plan Help",
                  action: "Click",
                  label: "Free Trial In Overview",
                })
              }
            >
              {"Start Your LaunchPlan Free Trial"}
            </Button>
          </div>
        </div>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(RealEstatePage)
